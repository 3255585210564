import React from "react"


function MessagesItem({username, usermessage, isActive, isOnline, img}) {
    return (
        <>
            <div className="messagesItemContainer">
                <div className={`${isOnline === true ? 'dashboardChatAvatar' : ''}`}>
                    <img className='dashBoardImg' src={`${img}`} alt="admin Profile"/>
                </div>
                <div className="messagesItemContent">
                    <div className="infoSection">
                        <p className="messagesUserName">
                            {username}
                        </p>
                        <div className={`messageTime ${isActive}`}>
                            <p className="timeStatus">
                                5 min ago
                            </p>
                            
                        </div>
                    </div>
                        <div className="messageStatus">
                            <p className={`messagePreview ${isActive}`}>
                                {usermessage}
                            </p>
                            <div className="readStatus"></div>
                        </div>
                </div>
            </div>
        </>
    )

}

export default MessagesItem