import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import searchIcon from '../../../public/images/search.png';
import MessagesItem from './MessagesItem';
import messagesIcon from '../../../public/images/image_message_icon.png';
import DUMMY_DATA from './DUMMY_DATA';

function Messages() {
    const [userMessage, setUserMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [userSearch, setUserSearch] = useState('');
    const [selectedChat, setSelectedChat] = useState(null);
    const messagesEndRef = useRef(null);

    
    useEffect(() => {
        const sortedData = DUMMY_DATA.sort((a, b) => {
            if (a.usertype === 'admin') return -1;
            if (b.usertype === 'admin') return 1;
            return 0;
        });

        setMessages(sortedData);

        const adminChat = sortedData.find(chat => chat.usertype === 'admin');
        if (adminChat) {
            setSelectedChat(adminChat);
        }
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const sendMessage = () => {
        if (userMessage.trim() !== '') {
            const timestamp = new Date().toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });
            const newMessage = {
                message: userMessage,
                timestamp: timestamp,
                sender: 'You',
                type: 'sent',
            };

            setMessages((prevMessages) =>
                prevMessages.map((chat) => {
                    if (chat.chatId === selectedChat.chatId) {
                        const updatedChat = { ...chat, messages: [...chat.messages, newMessage] };
                        setSelectedChat(updatedChat);
                        return updatedChat;
                    }
                    return chat;
                })
            );

            setUserMessage('');
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    

    const handleSelectChat = (chat) => {
        setSelectedChat(chat);
        setUserMessage('');
    };

    const userSearchHandler = (e) => {
        setUserSearch(e.target.value);
    };

    const userInputHandler = (e) => {
        setUserMessage(e.target.value);
    };

    const renderChatSection = () => {
        return (
            <div data-testid="chat-section" className="messagesContainer privateMessages">
                {selectedChat && (
                    <>
                        <div className="userSection" data-testid="selected-chat">
                            <img src={selectedChat.img} alt="user avatar" />
                            <p>{selectedChat.username}</p>
                        </div>
                        <div className="chatSection">
                            <div className="messages" data-testid="message-list">
                                {selectedChat.messages.map((msg, index) => (
                                    <div key={index} className={`message ${msg.type}`} data-testid={`chat-message-${index}`}>
                                        <p>{msg.message}</p>
                                        <span className="timestamp">{msg.timestamp}</span>
                                    </div>
                                ))}
                                <div ref={messagesEndRef}></div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };
        
    const renderInputSection = () => {
        return (
            <div className="inputSection" data-testid="input-section">
                <input
                    placeholder="Type your message..."
                    value={userMessage}
                    onChange={userInputHandler}
                    data-testid="message-input"
                />
                <button className="chatButton" onClick={sendMessage} data-testid="send-button">
                    <p>Send</p>
                </button>
            </div>
        );
    };

    return (
        <div data-testid="messages-test-id" >
            <Header />
            <div className="blank"></div>
            <div className="container py-4">
                <div className="row">
                    <div className="col-md-3">
                        <Sidebar id="6" />
                    </div>
                    <div className="col-md-9">
                        <div className="rightSide">
                            <div className="breadcrumbs">
                                <span className="active-page" data-testid="messages-header">Messages</span>
                            </div>
                            <div className="row">
                                <div className="col-md-5 chatFlex">
                                    <div className="messagesContainer" data-testid="messages-container">
                                        <div className="messagesTitle underscore" data-testid="messages-title">
                                            <p>Messages (2)</p>
                                            <img src={messagesIcon} alt="messages icon" />
                                        </div>
                                        <div className="inputField">
                                            <img src={searchIcon} alt="search" />
                                            <input
                                                placeholder="Search"
                                                onChange={userSearchHandler}
                                                data-testid="search-input"
                                            />
                                        </div>
                                        <div>
                                            <ul className="messagesList" data-testid="messages-list">
                                                {messages.map((chat) => (
                                                    <li
                                                        key={chat.chatId}
                                                        className={`messageItem ${
                                                            selectedChat?.chatId === chat.chatId ? 'active' : ''
                                                        }`}
                                                        onClick={() => handleSelectChat(chat)}
                                                        data-testid="message-item"
                                                    >
                                                        <MessagesItem
                                                            username={chat.username}
                                                            img={chat.img}
                                                            usermessage={chat.messages[chat.messages.length - 1].message}
                                                            isActive={`${selectedChat?.chatId === chat.chatId ? 'active' : ''}`}
                                                            isOnline={true}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <button className="newMessage" data-testid="new-message-button">
                                            <span>+</span>
                                            <p>Start new message</p>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-7 chatFlex">
                                    {renderChatSection()}
                                    {renderInputSection()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Messages;
