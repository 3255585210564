const DUMMY_DATA = [
    {
      chatId: '1',
      username: 'Ralph Edwards',
      isOnline:true,
      usertype: 'customer',
      img: '/images/adminProfile.jpg',
      unreadMessages:true, 
      messages: [
        {
          message: 'Hello, how can I help you today?',
          timestamp: '10:00am',
          sender: 'Ralph Edwards',
          type: 'received', 
        },
        {
          message: 'I need some information regarding your services.',
          timestamp: '10:02am',
          sender: 'You',
          type: 'sent', 
        },
        {
          message: 'Sure! What exactly would you like to know?',
          timestamp: '10:05am',
          sender: 'Ralph Edwards',
          type: 'received', 
        },
      ],
    },
    {
      chatId: '2',
      username: 'Admin',
      isOnline:true,     
      usertype: 'admin',
       img: '/images/adminProfile.jpg', 
      unreadMessages:false, 
      messages: [
        {
          message: 'Please review the latest updates to the system.',
          timestamp: '11:00am',
          sender: 'Admin',
          type: 'received',
        },
        {
          message: 'I will check it out and get back to you.',
          timestamp: '11:05am',
          sender: 'You',
          type: 'sent',
        },
      ],
    },
    {
      chatId: '3',
      username: 'Jane Cooper',
      isOnline:false,
      usertype: 'customer',
      img: '/images/adminProfile.jpg', 
      unreadMessages:true, 
      messages: [
        {
          message: 'Can you send me the invoice for last month?',
          timestamp: '1:15pm',
          sender: 'Jane Cooper',
          type: 'received',
        },
        {
          message: 'Sure, I will send it to your email shortly.',
          timestamp: '1:17pm',
          sender: 'You',
          type: 'sent',
        },
        {
          message: 'Thank you!',
          timestamp: '1:18pm',
          sender: 'Jane Cooper',
          type: 'received',
        },
        {
          message: 'Can you send me the invoice for last month?',
          timestamp: '1:15pm',
          sender: 'Jane Cooper',
          type: 'received',
        },
        {
          message: 'Thank you!',
          timestamp: '1:18pm',
          sender: 'Jane Cooper',
          type: 'received',
        },
      ],
    },
    {
      chatId: '4',
      username: 'John Doe',
      isOnline:false,
      usertype: 'customer',
       img: '/images/adminProfile.jpg', 
       unreadMessages:false, 
      messages: [
        {
          message: 'Is the product I ordered available?',
          timestamp: '2:30pm',
          sender: 'John Doe',
          type: 'received',
        },
        {
          message: 'Yes, it is in stock and ready to be shipped.',
          timestamp: '2:32pm',
          sender: 'You',
          type: 'sent',
        },
        {
          message: 'Great! When can I expect the delivery?',
          timestamp: '2:35pm',
          sender: 'John Doe',
          type: 'received',
        },
        {
          message: 'It should arrive by the end of the week.',
          timestamp: '2:40pm',
          sender: 'You',
          type: 'sent',
        },
      ],
    },
    {
      chatId: '5',
      username: 'Jack Grealish',
      isOnline:true,
      usertype: 'customer',
       img: '/images/adminProfile.jpg', 
       unreadMessages:false, 
      messages: [
        {
          message: 'Is the product I ordered available?',
          timestamp: '2:30pm',
          sender: 'John Doe',
          type: 'received',
        },
        {
          message: 'Yes, it is in stock and ready to be shipped.',
          timestamp: '2:32pm',
          sender: 'You',
          type: 'sent',
        },
        {
          message: 'Great! When can I expect the delivery?',
          timestamp: '2:35pm',
          sender: 'John Doe',
          type: 'received',
        },
        {
          message: 'It should arrive by the end of the week.',
          timestamp: '2:40pm',
          sender: 'You',
          type: 'sent',
        },
      ],
    },
  ];
  
  export default DUMMY_DATA;
  