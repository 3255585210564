import React from "react";

function ChatSectionItem({username, img, usermessage, isOnline, unreadMessages}) {
  const toggleStyles = unreadMessages ? "unreadMessage" : "readMessage";
  return (
    <>
      <div className="dashboardMessagesItemContainer underscore">
        <div className="dashboardMessageitemContent">
          <div className={`${isOnline === true ? "dashboardChatAvatar" : ""}`}>
            <img className="dashBoardImg" src={`${img}`} alt="admin Profile" />
          </div>
          <div className="messagesItemContentDashboard">
            <div className="infoSectionDashboard">
              <p className="messagesUserName">{username}</p>
              <p className={`messagePreviewDashboard ${toggleStyles}`}>
                {usermessage}
              </p>
            </div>
          </div>
        </div>
        <div className={`timeStatusDashboard ${toggleStyles}`}>
          <p>5 min ago</p>
          {toggleStyles === "unreadMessage" && (
            <div className="readStatusDashboard"></div>
          )}
        </div>
      </div>
    </>
  );
}

export default ChatSectionItem;
