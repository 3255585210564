import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import appConstant from '../shared/constant/constant.json';
import LoadingSpinner from '../shared/Loader';
import Moment from 'moment/moment';
import ScrollToTop from '../shared/ScrollToTop';
function Header(props) {
  const nav = useNavigate();
  const location = useLocation()
  let [notifications, setNotifications] = useState([]);
  let [isNotificationLoading, setIsNotificationLoading] = useState(false);
  let [organizationName, setOrganizationName] = useState();
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      nav('/');
    }
    if (localStorage.getItem('registration') === "false") {
      nav('/register-organization')
    }
    isActivated();
    notificationCount();
    VendorCompanyData();
  }, []);
  const params = {
    headers: {
      "Content-Type": 'application/json',
      "Access-Control-Allow-Origin": '*',
      "token": localStorage.getItem('token')
    }
  };
  const isActivated = () => {
    axios.get(`${appConstant.API_URL}/account_block/user_activate`, params)
      .then(res => {
        if (res?.status === 200) {
          if (res?.data?.activate) {

          } else {
            localStorage.clear('token');
            localStorage.clear('full_name');
            localStorage.clear('user_type');
            nav('/');
          }
        } else {
        }
      })
      .catch(error => {
      });
  }
  const VendorCompanyData = () => {
    axios.get(`${appConstant.API_URL}/bx_block_profile/show_registration`, params)
      .then(res => {
        if (res.data.status === 200) {
          setOrganizationName(res?.data?.data?.attributes?.organisation_name);
        } else {
        }
      })
      .catch(error => {
      });
  }
  const notificationCount = () => {
    axios.get(`${appConstant.API_URL}/bx_block_notifications/notification/total_count`, params)
      .then(res => {
        if (res?.data?.status === 200) {
          localStorage.setItem('notificationCount', res?.data?.total_count);
        } else {
          localStorage.setItem('notificationCount', 0);
        }
      })
      .catch(error => {
      });
  }
  const getNotifications = () => {
    setIsNotificationLoading(true);
    axios.get(`${appConstant.API_URL}/notifications/notifications?page=1&limit=3`, params)
      .then(res => {
        setIsNotificationLoading(false);
        if (res?.data?.status === 200) {
          setNotifications(res?.data?.data);
          notificationCount();
        } else {
          setNotifications([]);
        }
      })
      .catch(error => {
        setIsNotificationLoading(false);
      });
  }
  const updateNotifications = (notificationId, url) => {
    setIsNotificationLoading(true);
    const notificationsData = {}
    axios.put(`${appConstant.API_URL}/notifications/notifications/${notificationId}`, notificationsData, params)
      .then(res => {
        setIsNotificationLoading(false);
        if (res?.data?.status === 200) {
          nav(url);
        } else {
          setNotifications([]);
        }
      })
      .catch(error => {
        setIsNotificationLoading(false);
      });
  }
  const updateAllNotifications = () => {
    setIsNotificationLoading(true);
    const notificationsData = {}
    axios.put(`${appConstant.API_URL}/bx_block_notifications/notification/mark_all_read`, notificationsData, params)
      .then(res => {
        setIsNotificationLoading(false);
        if (res?.data?.status === 200) {
          notificationCount();
        }
      })
      .catch(error => {
        setIsNotificationLoading(false);
      });
  }
  return (
    <>
      <ScrollToTop />
      <header className='header fixed-top'>


        <div className='container header-content'>
          <div className='logo-container'>
            <Link to={location.pathname === "/register-organization" ? "/home/hire-equipment" : "/dashboard"} className="logo">Alyya</Link> <span className='divider'>|</span><span className='panel'>{organizationName}</span>
          </div>
 <nav className="navbar navbar-expand-lg navbar-light d-lg-flex d-none desktop">
          <div className='container header-content'>
            <div className='account-container'>
            
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="mynavbar">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className={"nav-link " + (props.id === "1" ? 'active' : '')} to="/home/hire-equipment">Hire Equipment</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (location?.state?.id.toString() === "2" ? 'active' : '')} to="/home/list-equipment">List Equipment</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (props.id === "3" ? 'active' : '')} to="/home/about-us">About Us</Link>
                  </li>
                  <li className="nav-item">
                    <Link className={"nav-link " + (props.id === "4" ? 'active' : '')} to="/home/contact-us">Contact Us</Link>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </nav>
         
          <div className='account-container social-links'>
            <span className="dropdown notice-link">
              <span className='cart-count'>{localStorage.getItem('notificationCount')}</span>
              <button type="button" className="notice-link" data-bs-toggle="dropdown" onClick={() => { getNotifications() }}>
                <img src="/images/notification.png" alt="notification" />
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                {isNotificationLoading ? <LoadingSpinner /> :
                  <>
                    <li>
                      <div className="dropdown-item-text"><span>Notifications</span> <span className='links' style={{ cursor: 'pointer' }} onClick={() => { updateAllNotifications() }}>Mark all as read</span>
                      </div>
                    </li>
                    {notifications?.length > 0 && notifications?.map((result, i) =>
                      <>
                        <li key={i} onClick={() => { updateNotifications(result?.attributes?.id, result?.attributes?.url) }} style={{ cursor: 'pointer' }} className={!result?.attributes?.is_read ? 'unread' : ''}>
                          <div className="dropdown-item">
                            <div className='d-flex align-item-start'>
                              <div className='position-relative'><img className='notice-img rounded-circle me-3' src={result?.attributes?.image ? result?.attributes?.image : '/images/userr.png'} alt="person" />
                                {!result?.attributes?.is_read && <span className='green-dot'></span>}
                              </div>
                              <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-item-center'><span
                                  className='name' style={{ textTransform: 'capitalize', width: '60%' }}>{result?.attributes?.full_name}</span><span className='time'>{Moment(result?.attributes?.created_at).startOf('minutes').fromNow()}</span></div>
                                <div className='time'>{result?.attributes?.contents}</div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </>
                    )}
                  </>
                }
                <li>
                  <div className='dropdown-item-text justify-content-center'><Link className='links' to="/notification" style={{ cursor: 'pointer' }}>See all notifications</Link></div>
                </li>
              </ul>
            </span>
            <div className='profile-image'>{localStorage.getItem('image') ? <img src={localStorage.getItem('image')} alt="profile" /> : <img src="/images/userr.png" alt="profile" />}</div>
            <div className='profile-detail'>
              <div className="btn-group">
                <button type="button" className="person-name dropdown-toggle" data-bs-toggle="dropdown" style={{ textTransform: 'capitalize' }}>{localStorage.getItem('full_name')}<br /></button>
                <div className="dropdown-menu">
                  <Link to="/account" className="dropdown-item">My Account</Link>
                  <Link to="/logout" className="dropdown-item">Logout</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
};

export default Header;
